import React from 'react'
// 1. Import useStyleConfig
import { useStyleConfig, Box } from "@chakra-ui/react"

export const H1 = (props) => {
  const { size, variant, ...rest } = props
  // 2. Reference `Button` stored in `theme.components`
  const styles = useStyleConfig("H1", { size, variant })
  // 3. Pass the computed styles into the `sx` prop
  return <Box as="h1" sx={styles} {...rest} />
}

export const H2 = (props) => {
  const { size, variant, ...rest } = props
  // 2. Reference `Button` stored in `theme.components`
  const styles = useStyleConfig("H2", { size, variant })
  // 3. Pass the computed styles into the `sx` prop
  return <Box as="h2" sx={styles} {...rest} />
}

export const H3 = (props) => {
  const { size, variant, ...rest } = props
  // 2. Reference `Button` stored in `theme.components`
  const styles = useStyleConfig("H3", { size, variant })
  // 3. Pass the computed styles into the `sx` prop
  return <Box as="h3" sx={styles} {...rest} />
}

export const H4 = (props) => {
  const { size, variant, ...rest } = props
  // 2. Reference `Button` stored in `theme.components`
  const styles = useStyleConfig("H4", { size, variant })
  // 3. Pass the computed styles into the `sx` prop
  return <Box as="h4" sx={styles} {...rest} />
}

export const H5 = (props) => {
  const { size, variant, ...rest } = props
  // 2. Reference `Button` stored in `theme.components`
  const styles = useStyleConfig("H5", { size, variant })
  // 3. Pass the computed styles into the `sx` prop
  return <Box as="h5" sx={styles} {...rest} />
}

export const H6 = (props) => {
  const { size, variant, ...rest } = props
  // 2. Reference `Button` stored in `theme.components`
  const styles = useStyleConfig("H6", { size, variant })
  // 3. Pass the computed styles into the `sx` prop
  return <Box as="h6" sx={styles} {...rest} />
}
export const Span = (props) => {
  const { size, variant, ...rest } = props
  // 2. Reference `Button` stored in `theme.components`
  const styles = useStyleConfig("Span", { size, variant })
  // 3. Pass the computed styles into the `sx` prop
  return <Box as="span" sx={styles} {...rest} />
}